import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

class Features extends Component {
  render() {
    return (
      <Fragment>
        <div className='call-to-action-wrapper call-to-action text-white-wrapper  ptb--120'>
          <Container className='text-center ptb-50 features'>
            <Row>
              <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div class="inner text-center">
                  <h2 className="title">Get Direct Admission to RostSMU<br /> Join Reliable Russian Educational Consultants</h2>
                  <p className="blogtype">(Authorized official representative of Indian &amp; foreign students)</p>
                  <h2 className="title">Admission is open for the 2022-23 academic year<br /> interesting students may contact us</h2>
                  <p className="blogtype">Contact numbers: +917042284508 (Ind) | +79604430042 (Rus), Toll-free: 1800-572-5827 (24*7 Students Helpline Number)</p>
                  <p className="blogtype">For Students: contact@rrecrussia.com,  rrec.educonsultants@gmail.com</p>
                  <p className="blogtype">Students or parents can write to us on at WhatsApp also +79515277045 (Rus)</p>
                  <a class="rn-button-style--2" href="https://rrecrostov.com/official-representative-of-rostsmu" target="_blank"><span>Contact Us </span></a> </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }
}

export default Features
