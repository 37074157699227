import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";


// import required modules
import { Navigation } from "swiper";

import BgSlider from '../../assets/images/BgSlider.jpg';
import BgSlider1 from '../../assets/images/BgSlider1.jpg';


class HomeSlider extends Component {
     render() {
      SwiperCore.use([Autoplay]);

    return (
     <Fragment>
         <>
  <Swiper navigation={true} modules={[Navigation]} className="mySwiper"  autoplay={{ delay: 4000 }}>
  <section className="banner">
    <div className='slider'>
    <div className="swiper-wrapper">
            <SwiperSlide className="swiper-slide">
            <div class="slide-inner">                         
             <img  className="slide-image" src={BgSlider}  alt="First slide" />
              </div>             
            </SwiperSlide>
                  
            <SwiperSlide className="swiper-slide">
            <div class="slide-inner">                         
             <img  className="slide-image" src={BgSlider1}  alt="First slide" />
              </div>             
            </SwiperSlide>
              
                  <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                  </div>
                  </div>
                  </section>
            </Swiper>
          </>

     </Fragment>
    )
  }
}

export default HomeSlider
