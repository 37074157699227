import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Socialmedia from './Socialmedia'
import Logo from '../../assets/images/logo-f.webp';

class Footer extends Component {
  render() {
    return (
     <Fragment>
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="4">
            <div className="wrapper plr--50 plr_sm--20">
        <Container>
          <Row>
          <Col  key={1} xl={4} lg={4} md={4} sm={4} xs={4}>
         
          </Col>

          <Col  key={1} xl={4} lg={4} md={4} sm={4} xs={4}>
          <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                            <li><a href="https://www.facebook.com/RRECTeamOfficialpage/" target="_blank"><i className="fab fa-facebook"></i></a></li>
										<li><a href="#"><i className="fab fa-twitter"></i></a></li>
										<li><a href="https://www.instagram.com/rrecrussia_officials/" target="_blank"><i className="fab fa-instagram"></i></a></li>
										<li><a href="https://www.youtube.com/channel/UChb_aZlyYRcUqmsZsEs_tIA" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                        <li><div id="google_translate_element">  </div></li>
								
                            </ul>
                        </div>
          </Col>

          <Col  key={1} xl={4} lg={4} md={4} sm={4} xs={4}>
          <div className="inner text-lg-end text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © 2022 RostSMU presented by RREC Team</p>
                            </div>
                        </div>
          </Col>

          </Row>
          </Container>
         </div>
        </div>
    <Socialmedia />
     </Fragment> 



    )
  }
}

export default Footer
