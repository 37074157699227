import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';
import Menu from './Menu';
import News from  './News';
import Topheader from './Topheader';




 class Header extends Component {
     componentDidMount(){
          window.scroll(0,0)
      } 
     render() {
          return (
               <Fragment>
                      <News />
                    <header className="main_header_area">
                    <div className="header-content">
                
               <Topheader/>         
             
              </div>
              </header>
              <Menu />
               </Fragment>
          )
     } 
}
export default Header