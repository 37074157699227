import React, { Component, Fragment } from 'react'
import Services from '../components/home/Services';
import HomeSlider from '../components/home/HomeSlider';
import Features from '../components/home/Features';
import Faq from '../components/layout/Faq';
import { Helmet } from "react-helmet";
import HomeContent from '../components/home/Faq';
import Youtube from '../components/home/Youtube';
import Counter from '../components/home/Counter';





export class Home extends Component {
  render() {
    
    return (
    <Fragment>
      <Helmet>
      <title>Rostov State Medical University | MBBS in Russia 2022</title>
	<meta name="description" content="Get Direct Admission in MBBS in Russia, Faculties, Recognition, Fees, Admission Intake 2022, Eligibility Criteria, Documents required, Advantages of Studying MBBS in Rostov State Medical University" />
	<meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://rrecrostov.ru/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University | MBBS in Russia 2022" />
	<meta property="og:description" content="Get Direct Admission in MBBS in Russia, Faculties, Recognition, Fees, Admission Intake 2022, Eligibility Criteria, Documents required, Advantages of Studying MBBS in Rostov State Medical University" />
	<meta property="og:url" content="https://rrecrostov.ru/" />
	<meta property="og:site_name" content="Study MBBS in Rostov" />
	<meta property="article:modified_time" content="2021-12-23T09:33:27+00:00" />
	<meta property="og:image" content="https://rrecrussia.com/wp-content/uploads/2019/07/1.jpeg" />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:label1" content="Est. reading time" />
	<meta name="twitter:data1" content="10 minutes" />
      </Helmet>   
      <HomeSlider />
      <Services />
      <Counter />
      <Faq />
      <Youtube/>
      <Features />
       </Fragment>
    )
  }
}

export default Home;
