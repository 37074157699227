import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap'


export class Faq extends Component {
    render() {
        return (
           <Fragment>
              <div className='rn-featured-service-area pt--90 pb--120 bg_color--5'>
         <Container>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
         
         
          <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header><h4>Rostov State Medical University – A Deam University For Indian MBBS Aspirants</h4></Accordion.Header>
    <Accordion.Body>
  
   <h1><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> <br/> A Deam University For Indian MBBS Aspirants</h1>
            <p><a href="https://thedoctorsiea.in/rostov-state-medical-university/">Rostov State Medical University</a>  for <a href="https://thedoctorsiea.in/">MBBS in Russia</a> is one of the leading Russian Medical Universities, providing a broad set of educational programs. The teaching staff of the University consists of scientists and medical practitioners who are committed to their work. <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> is the successor of Russian Warsaw University, evacuated to Rostov-on-Don and converted into The Don University. The best representatives of the medical schools of Warsaw, Moscow, St. Petersburg, and Kazan were collected on its medical faculty and have founded their own unique Rostov medical school, which has become one of the best in Russia.</p>
            <p><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> is the best medical university for <a href="https://thedoctorsiea.in/" target="_self">MBBS in Russia</a> for foreign students as well as for local students. In 1930, the Medical Faculty of the University was reorganized into the Institute of Medicine, which has become the largest medical university in the South of the USSR. In the 50s, the Institute has grown significantly, the university became the first category, won the right to conduct the defense of dissertations for awarding the degree of Doctor of Science. It has organized the Evening Department and the Faculty for Advanced Training of physicians. </p>
            <p>In the 60s, there were opened The Central Scientific Research Laboratory, Preparatory Faculty on Training Foreign Citizens, several new departments, and at the same time there was an improved clinical base.</p>
       
            <p>In 1994, the Institute was granted University status, and then there were organized new faculties. Today <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> is the largest medical research center in Southern Russia. Rostov State Medical University has its own well-developed academic infrastructure, which includes the unique medical library, a University Clinic, a sports and leisure complex, and a network of hostels for students. Students have access to the free Internet, electronic library resources, and other modern means of communication during their training.</p>
            <p>Diploma of Physician of the <a href="https://rrecrostov.com/" target="_self">Rostov State Medical University</a> which has been training specialists for foreign countries since 1961 is highly appreciated abroad. In 1964 the Dean&rsquo;s office of Faculty on Training Foreign Students and Postgraduates was organized. Foreign students are trained at the faculties of General Medicine, Pediatrics, Pharmacy, and Stomatology. Annually the University produces about 100/120 foreign specialists from about 100 countries of Asia, Africa, and Latin America. Students from different countries live as one big friendly family successfully obtaining one of the noblest and human professions — medical one. Training foreign citizens on the postgraduate stage at the university has been carrying out since 1965. Annually 4-5 foreign postgraduates successfully defend their thesis by awarding the scientific title Ph.D. </p>
        
            <p>Young scientists-foreigners represent our University at international congresses and symposia on actual problems of medicine in Italy, France, Sweden, Germany, the USA, Turkey, and other countries. Many graduates of the <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> after completing the residency and postgraduate course became prominent statesmen, heads of clinics, hospitals, departments of Ministries of Health in their Motherland. </p>
     
  
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><h4>RostSMU Faculties:</h4></Accordion.Header>
    <Accordion.Body>
  
     <h2>RostSMU Faculties:</h2>
            <ul>
            <li><a href="https://rrecrostov.com/">General Medicine (MBBS)</a></li>
             <li>Pediatrics</li>
             <li>Dentistry (BDS)</li>
             <li>Pharmacy</li>
             <li>Preparatory Faculty</li>
              </ul>
            <p>Graduates of the <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> have all the necessary knowledge, skills, and qualifications that enable them to succeed in their future careers. They are in demand in the labor market.</p>
      
            <p>RostSMU is the first choice for foreign students as well as for Indian students for studying <a href="http://www.rrecrostov.com/">Medicine (MBBS) in Russia</a>. Top Rank University for Indian students, as per Embassy and Medical Universities RostSMU listed in top 20 universities of RF.</p>
            <p>Country Rank: 248, World Rank: 5344.</p>
  
  
    </Accordion.Body>
  </Accordion.Item>


<Accordion.Item eventKey="2">
    <Accordion.Header><h4>Rostov State Medical University  Recognition:</h4></Accordion.Header>
    <Accordion.Body>
   
      <h2><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> Recognition: </h2>
            <ul>
              <li>Ministry of Public Health and Education, Russian Federation.</li>
              <li><a href="https://rrecrostov.com/">National Medical Commission of India (NMC).</a></li>
              <li>World Health Organization.</li>
              <li>Canadian Medical Council.</li>
              <li>Nigerian Medical and Dental Council.</li>
              <li>Ghana Medical and Dental Council.</li>
              <li>Zambia Medical and Dental Council</li>
              <li>Bangladesh Medical and Dental Council.</li>
              <li>German Science and Humanities Council.</li>
              <li>American Osteopathic Association.</li>
              <li>Pakistan Medical and Dental Council.</li>
              <li>Lebanese Order of Physicians.</li>
              <li>UAE Medical Councils.</li>
              <li><a href="https://rostgmu.net/">RostSMU</a> is listed in the top Medical Universities of Russia.</li>
            </ul>
   
    </Accordion.Body>
  </Accordion.Item>


  <Accordion.Item eventKey="3">
    <Accordion.Header><h4>Rostov State Medical University  Fees | Fee structure 2022-23</h4></Accordion.Header>
    <Accordion.Body>
    <h2><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> Fees | Fee structure 2022-23</h2>
            <h3><a href="https://rrecrostov.com/">For the latest fees visit our official website here </a></h3>
      
            <h2><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> Hostels | Accommodation</h2>
            <p><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> has five hostels, from the two are situated in University campus which is for new students, Hostels are very good and comfortable as compare to other universities, especially it&rsquo;s very good for new students that two hostels are near, which are situated on the campus of University in walking distance. The canteen facility is available for a daytime meal. </p>
       
            <h2><a href="https://rrecrostov.com/"  target="_self">Rostov state medical university</a> Admission Intake 2022</h2>
            <p>One intake in a year for the MBBS course: English Medium for foreign students: May-September 20. And for preparatory course intake available from June to December 10. For other faculties only from June-August 20.</p>
    </Accordion.Body>
  </Accordion.Item>
  
   <Accordion.Item eventKey="4">
    <Accordion.Header><h4>What are the Eligibility Criteria for study MBBS at Rostov State Medical University for Indians?</h4></Accordion.Header>
    <Accordion.Body>
    <h2>What are the Eligibility Criteria for study MBBS at <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> for Indians?</h2>
            <p>Eligibility criteria for study MBBS in RostSMU are based on your 12th standard marks or grades.</p>
            <ul>
              <li>You should be at the age of 17 on or before 31st December during the current year of admission.</li>
              <li>The student must have more than 65% marks in English, Biology, and Chemistry (per each subject). Admission is mainly based on these subjects and entrance exams on arrival for biology and chemistry.</li>
              <li>NEET  (Qualified marks) is mandatory to study MBBS at <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a>.</li>
            </ul>
            <p>The admission process begins by you sending us the scanned copy of the documents listed below, for more details or free counseling consult RREC TEAM (the only authorized representative of <a href="https://rrecrostov.com/"  target="_self">Rostov state medical university</a>, Russia)</p>
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="5">
    <Accordion.Header><h4>Documents required for study MBBS in Rostov State Medical University, Russia (For Indian Students)</h4></Accordion.Header>
    <Accordion.Body>
    
     <h2>Documents required for study MBBS in <a href="https://rrecrostov.com/">Rostov State Medical University</a>, Russia (For Indian Students)</h2>
            <ul>
              <li>Filled application form</li>
              <li>Passport with a validity of a minimum of two years.</li>
              <li>10th, 12th Certificates, and mark-sheets.</li>
              <li>20  passport size photographs 35*45 mm @80% face at the matte finish paper with a white background.</li>
              <li>Medical tests: HIV test, Blood test, Health Fitness certificate.</li>
            </ul>
    
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="6">
    <Accordion.Header><h4>For Other Countries</h4></Accordion.Header>
    <Accordion.Body>
   
   
            <h4>For Other Countries: </h4>
            <ul>
              <li>Filled application form</li>
              <li>Passport with a validity of a minimum of two years.</li>
              <li>Secondary school certificate (English, Chemistry &amp; Biology)</li>
              <li>Medical fitness certificate</li>
              <li>20 Photos (passport size 35*45 mm at the matte paper)</li>
            </ul>
            <p>We will submit the documents to the university as per their requirement and after checking your eligibility university will provide you the admission letter, which will take officially 5-7 working days and the university will apply for the invitation letter from the federal migration services of the city which will take 20-28 working days. We will do all documentation including an affidavit, visa, ticket, apostles, etc (for Indian students). For other countries&rsquo; aspirants, we will provide admission and invitation letter and will provide complete guidelines regarding visas, tickets, etc. An incoming student enrolled through <a href="https://rrecrostov.com/">RREC Pvt Ltd</a> (the official representative of Indians &amp; International Students of <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> will receive by their officials at Moscow or Platov International Airport Rostov-on-Don city. </p>
           
            <h3><a href="https://rrecrostov.com/">Note: Beware of unauthorized agents/consultants. For more information, you can consult directly to our official representative at +7960-443-0042, +91-70422-84508</a></h3>
   
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="7">
    <Accordion.Header><h4>Advantages of Studying MBBS in Rostov State Medical University</h4></Accordion.Header>
    <Accordion.Body>
   
     <h2>Advantages of <a href="https://rrecrostov.com/">Studying MBBS in Rostov State Medical University</a>: </h2>
            <ul>
              <li>An entrance test is required to enter the first course of all faculties to check the basic knowledge of the student.</li>
              <li>No Donation required to study MBBS in RostSMU. The tuition fee is quite low and affordable.</li>
              <li>RostSMU is recognized by NMC, WHO, BMDC, NMDC, GMC, and degrees are recognized globally.</li>
              <li>RostSMU uses the European Credit Transfer System (ECTS), therefore one academic year of full-time studies is equivalent to 60 ECTS credits.</li>
              <li>Wide range of possibilities and broad exposure to clinical practice due to highly equipped multi-profile hospitals.</li>
              <li>The medical degree that students get from <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> is globally renowned and holds great worth.</li>
              <li>Graduates of <a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a> become eligible for working in government &amp; private hospitals after completing their MBBS Course.</li>
              <li>Particular Department, Clinics, Hospitals, offer practical training to medical students.</li>
              <li>Medical Course (MBBS) offered to Indian &amp; Foreign students in English Medium.</li>
              <li>Hostel accommodations are comfortable as per student&rsquo;s comfort and starting 1-3 years students will live in the campus of the university.</li>
              <li>Indian Food is available.</li>
            </ul>
   
    </Accordion.Body>
  </Accordion.Item>
  
  
       <Accordion.Item eventKey="8">
    <Accordion.Header><h4>Rostov State Medical University</h4></Accordion.Header>
    <Accordion.Body>
   
    <h3><a href="https://rrecrostov.com/"  target="_self">Rostov State Medical University</a>;</h3>
            <ul>
              <li>Having its own on-campus Central Scientific Research Laboratory (CSRL).</li>
              <li>Comprehensive scientific-investigation activities.</li>
              <li>Own Military Training Center.</li>
              <li>Having its own Medical University Clinic with 860 beds.</li>
              <li>17 clinical departments and 20 specialized departments in the clinic.</li>
              <li>Five dedicated buildings, including hostel, library, etc.</li>
              <li><a href="https://www.youtube.com/watch?v=_yoy_HK5NeA&t=31s">Rostov State Medical University hostel </a>facilities are quite satisfying and affordable.</li>
              <li>Four council candidates and doctoral dissertations.</li>
              <li>Member of the North Caucasus Research Center High School.</li>
              <li>Some encouraging <a href="https://www.youtube.com/watch?v=cn1LvbvhNvU&t=1067s">Rostov State Medical University reviews</a>.</li>
            </ul>
            <p>In a nutshell, RostSMU will be an exciting and promising option for medical education in Russia. <a href="https://rrecrostov.com/">The RREC Team</a>, the official representative of RostSMU will always be there to provide all assistance and consultation for admission in RostSMU. Feel free to contact us for a free consultation at 1800-572-5827</p>
   
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="9">
    <Accordion.Header><h4>Rostov-on-Don City</h4></Accordion.Header>
    <Accordion.Body>
   <h2>Rostov-on-Don City:</h2>
                       <h4>City Day‎: ‎Third Sunday of September, Country: ‎Russia, City status since ‎1796, Founded: ‎1749</h4>
                    <p>Rostov-on-Don or Rostov-na-Donu is the largest city in the south of Russia, the capital of the Rostov region, and the Southern Federal District. It is a major administrative, cultural, scientific, educational, and industrial center and a major transport hub of southern Russia. to the southeast of the East European Plain, on the Don River, it lies 32 kilometers from the Sea of Azov. The southwestern suburbs of the city about the Don River delta. Informally, Rostov-on-Don is called &ldquo;the Gate to the Caucasus&rdquo; and the southern capital of Russia. The climate is temperate continental. The average temperature in January is minus 3 degrees Celsius, in July – plus 23.4 degrees Celsius. </p>
                    <p>The Rostov Regional Museum of Local History has collections on Cossack lore and war history. Nearby, Rostov Regional Museum of Fine Arts displays Russian art from the 16th–20th centuries. The Rostov Academic Drama Theater of Maxim Gorky, built in the shape of a tractor, presents classic and contemporary plays. Outside, Theater Square has fountains and a Ferris wheel. </p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="10">
    <Accordion.Header><h4>Sports</h4></Accordion.Header>
    <Accordion.Body>
   
     <h3>Sports:</h3>
                    <h4>Rostov-on-Don is one of the host cities for the 2018 FIFA World Cup.</h4>
                    <ul>
                      <li>FC Rostov &amp; SKA Rostov-on-Don (football)</li>
                      <li>Rostov-Don (Basketball and Handball)</li>
                      <li>HC Rostov (Ice Hockey)</li>
                      <li>Rostov Arena (Football)</li>
                    </ul>
                    <p>Rostov-on-Don (the Capital city of South Russia) is 1198 km (745 miles) from Moscow. By Car 12 hours, 30 minutes. By Flight 1 hour, 50 minutes. , By Bus and Train 15 hrs, 30 minutes. Rostov-on-Don having International Airport &lsquo;&rsquo;Platov International Airport&rsquo;&rsquo; having direct flights from all over the world. And same from Moscow 8-12 flights daily.</p>
   
    </Accordion.Body>
  </Accordion.Item>





</Accordion>
          </Col>
          </Row>
          </Container>
          </div>
           </Fragment>
            )
        }
    }
    export default Faq;