import React, { Component, Fragment } from 'react'
import {Container,Row,Col} from 'react-bootstrap'

class News extends Component {
    render() {

        return (       
         <Fragment>   
            <Container fluid={true} className="news">
                <Container>
                <Row>
                <Col lg={10} md={10} sm={10}> 
                <marquee scrollamount="3" direction="left" onMouseOver="stop()" onMouseOut="start();">	<span class="text-white"><span className="blink-text">Breaking News:</span> Dear students & parents, Admission is open for <span className="blink-text">2022</span> academic year. Get direct admission today with our Official Admission Partner of <span className="blink-text">Rostov State Medical University Reliable Russian Educational Consultants</span>. Beware from unauthorized agents.</span></marquee> 
         
                    </Col>
                    <Col lg={2} md={2} sm={2}> 
                   <div id="google_translate_element">  </div>
                    </Col>
                </Row>
            </Container>
            </Container>
            </Fragment>	
     )
    }
}
export default News;