import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

 class Counter extends Component {
  render() {
    return (
    <Fragment>
 <div className="rn-countreupup-area pt--80 pb--120 bg_image bg_image--17 bg-position-fixed theme-text-white" data-black-overlay="7">
 <Container className='text-center ptb-50 features'>
          <Row>
          <Col  key={1} xl={3} lg={3} md={3}  sm={12} xs={12}>

          <div class="rn-counterup counterup_style--1 text-white">
                                <h5 class="counter count">94,532</h5>
                                <p class="description">FOREIGN FOLLOWERS</p>
                            </div>

              </Col>

              <Col  key={1} xl={3} lg={3} md={3}  sm={12} xs={12}>

              <div class="rn-counterup counterup_style--1 text-white">
                                <h5 class="counter count">4,600</h5>
                                <p class="description">INTERNATIONAL GRADUATES OF ROSTSMU</p>
                            </div>
              
              </Col>


              <Col  key={1} xl={3} lg={3} md={3}  sm={12} xs={12}>
              <div class="rn-counterup counterup_style--1 text-white">
                                <h5 class="counter count">1,100</h5>
                                <p class="description">FOREIGN STUDENTS ENROLLED</p>
                            </div>
              </Col>

              <Col  key={1} xl={3} lg={3} md={3} sm={12} xs={12}>
              <div class="rn-counterup counterup_style--1 text-white">
                                <h5 class="counter count">1,000</h5>
                                <p class="description">CERTIFIED TEACHERS</p>
                            </div>
              </Col>

              </Row>
              </Container>

 </div>
        
    </Fragment>
    )
  }
}

export default Counter
