import React, { Component, Fragment } from 'react'
import {Navbar,Container, Row, Col,Button, Nav, NavDropdown} from 'react-bootstrap';
import MainLogo from './MainLogo';

class Menu extends Component {
  render() {
    return (
      <Fragment>
        
<Navbar  expand="lg" className='main-navbar'>
  <Container>
    <Navbar.Brand href={process.env.PUBLIC_URL + '/'}>  <MainLogo /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className="main-menu menu-mobile nav  header_menu">
      <Nav className="ms-auto menu navbar-nav">
     
<Nav.Link href="https://rrecrostov.com/apply-now" target="_blank" className="button rn-button-style--1">Apply Now</Nav.Link>     
  
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

               </Fragment>
    )
  }
}

export default Menu
