import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';
import Menu from './Menu';
import News from  './News';




 class Topheader extends Component {
     componentDidMount(){
          window.scroll(0,0)
      } 
     render() {
          return (
               <Fragment>
                   <Container>
                   <Row>
                   <Col lg={6} md={6} sm={6}> 
                    <div className="links links-left">
                    <ul>
                        <li><a href="tel:+91-7042284508"><i className="fa fa-phone"></i> +91-7042284508,</a> <a href="tel:+91-7042284509">+91 7042284509</a></li>
                        <li><a href="mailto:contact@rrecrussia.com"><i className="fa fa-envelope-open"></i> contact@rrecrussia.com</a></li>                      
                    </ul>
                </div>
                </Col>
                <Col lg={6} md={6} sm={6}> 
                <div className="links links-right">
                  
                            <ul className="social-links">
                            <li><a href="https://www.facebook.com/RRECTeamOfficialpage/" target="_blank"><i className="fab fa-facebook"></i></a></li>
										<li><a href="#"><i className="fab fa-twitter"></i></a></li>
										<li><a href="https://www.instagram.com/rrecrussia_officials/" target="_blank"><i className="fab fa-instagram"></i></a></li>
										<li><a href="https://www.youtube.com/channel/UChb_aZlyYRcUqmsZsEs_tIA" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                        <li><div id="google_translate_element">  </div></li>
								
                            </ul>
                       
                </div>
                </Col>
                </Row>
                </Container>
               </Fragment>
          )
     } 
}
export default Topheader