import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

class Youtube extends Component {
  render() {
    return (
   <Fragment>

<div className='rn-featured-service-area pt--90 pb--120 bg_color--4'>
         <Container>
          <Row>
          <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
<h3>Prominent Medical University of Russia; RostSMU </h3>
<iframe width="100%" height="450" src="https://www.youtube.com/embed/d1C_DFa5v7M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
<h3>Rostov-on-Don, Capital city of South Russia</h3>
<iframe width="100%" height="450" src="https://www.youtube.com/embed/WXsS5xa3ELw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</Col>
              </Row>
              </Container>
              </div>
   </Fragment>
    )
  }
}

export default Youtube
