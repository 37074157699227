import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Blog1 from '../../assets/images/blog-01.jpg';
import Blog2 from '../../assets/images/blog-02.jpg';
import Blog3 from '../../assets/images/blog-03.jpg';
import Blog4 from '../../assets/images/blog-04.jpg';
import Blog5 from '../../assets/images/blog-05.jpg';
import Blog6 from '../../assets/images/blog-06.jpg';
import Blog7 from '../../assets/images/blog-07.jpg';

class Services extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: true,
      autoplaySpeed:3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
              }
          },
          {
              breakpoint: 480,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
  };
    return (
      <Fragment>
         <div class="rn-blog-area rn-section-gap bg_color--1">
          <Container>
              
              <Row>
              <Slider {...settings}>
               <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
                          <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={process.env.PUBLIC_URL + '/'}>
                                        <img className="w-100" src={Blog1} alt="Blog Images" />
                                    </a>
                                </div>
                                <div className="content">
 <h4 className="title"><a href={process.env.PUBLIC_URL + '/'}>GLOBAL RECOGNITION</a></h4>
 <p className="blogtype">RostSMU is recognized by WHO, NMC & all other major medical councils worlwide. RostSMU degree is recognized globally.
</p>
                                     <div className="blog-btn">
                                        <a className="rn-btn text-white" href={process.env.PUBLIC_URL + '/'}>Read More</a>
                                    </div>
                                </div>
                            </div>
                 
              </Col>

              <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
                          <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={process.env.PUBLIC_URL + '/'}>
                                        <img className="w-100" src={Blog2} alt="Blog Images" />
                                    </a>
                                </div>
                                <div className="content">
<h4 className="title"><a href={process.env.PUBLIC_URL + '/'}>BOOKS & LIBRARY
</a></h4>
                                    <p className="blogtype">The library of the RostSMU is one of the largest medical libraries in the Southern Federal District, it was created in 1933.</p>
                                     <div className="blog-btn">
                                        <a className="rn-btn text-white" href={process.env.PUBLIC_URL + '/'}>Read More</a>
                                    </div>
                                </div>
                            </div>
                 
              </Col>
              
              <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
                          <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={process.env.PUBLIC_URL + '/'}>
                                        <img className="w-100" src={Blog3} alt="Blog Images" />
                                    </a>
                                </div>
                                <div className="content">
                                <h4 className="title"><a href={process.env.PUBLIC_URL + '/'}>RSMU GRADUATES
</a></h4>
                                    <p className="blogtype">RostSMU is 100 years old government Medical University. Thousands of our Graduates are working globally.

</p>
                                     <div className="blog-btn">
                                        <a className="rn-btn text-white" href={process.env.PUBLIC_URL + '/'}>Read More</a>
                                    </div>
                                </div>
                            </div>
                 
              </Col>
              
                 <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
                          <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={process.env.PUBLIC_URL + '/'}>
                                        <img className="w-100" src={Blog4} alt="Blog Images" />
                                    </a>
                                </div>
                                <div className="content">
                                <h4 className="title"><a href={process.env.PUBLIC_URL + '/'}>Admissions 2022
</a></h4>
                                    <p className="blogtype">Dear aspirants, get direct admission to RostSMU contact RREC Team at contact@rrecrussia.com

</p>
                                     <div className="blog-btn">
                                        <a className="rn-btn text-white" href={process.env.PUBLIC_URL + '/'}>Read More</a>
                                    </div>
                                </div>
                            </div>
                 
              </Col>
              
               
             

              </Slider>
             
              </Row>
          </Container>
          </div>
          </Fragment>
    )
  }
}

export default Services
