import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import wht from '../../assets/images/wht.webp';
import phone from '../../assets/images/phone.webp';
import apply from '../../assets/images/app.webp';
import partner from '../../assets/images/part.webp';


class Socialmedia extends Component {
    render() {
        return (
            <Fragment>


                <div className="social1">
                    <ul>
                        <li><a href="https://wa.me/+79515277045" target="_blank"><img src={wht} className="img-fluid" alt="" /></a></li>
                        <li><a href="tel:+917042284508" target="_blank"><img src={phone} className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>



                <div className="app">
                    <ul>
                        <li><a href="https://rrecrostov.com/apply-now" target="_blank" ><img src={apply} className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>
                <div className="partner">
                    <ul>
                        <li><a href="https://rrecrostov.com/partner-with-us" target="_blank"><img src={partner} className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>

            </Fragment>
        )
    }
}

export default Socialmedia
