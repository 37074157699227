import React, { Component, Fragment } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';

import Home from '../pages/Home';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";



const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
class AppRoute extends Component {
  render() {
    return (

      <Fragment>    
     <Header />
     <Router basename={process.env.PUBLIC_URL} history={history}>  

      <Route exact path='/' component={ Home } /> 
     
      
     

    </Router>  
    <Footer />
 </Fragment>

    )
  }
}

export default AppRoute;
